<template>
  <div>
    <a-spin :spinning="loading">
      <a-form ref="form" name="form" :model="formState" @finish="onSearch">
        <a-row>
          <a-form-item class="ui-form__item" name="organizationId" label="影院组织">
            <a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
              <!-- <a-select-option :value="0">全部</a-select-option> -->
              <a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
            <a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;" :dropdownMatchSelectWidth="false">
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="分账组" class="ui-form__item" name="allocateGroupId">
            <a-select v-model:value="formState.allocateGroupId" style="width: 180px;" placeholder="请选择分账组" :dropdownMatchSelectWidth="false">
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option v-for="item in groupList" :value="item.id" :key="item.id">{{ item.title }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-row>

        <a-row class="ui-btnBox">
          <a-col :span="18">
            <a-button v-permission="['ledger_setting_ticket_group']" type="primary"  @click="onAddTap()">新增</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right;">
            <a-button type="primary" html-type="submit">搜索</a-button>
            <a-button style="margin-left: 20px;" @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
      <!--            <div style="margin-top: 10px;color: red;">注:当前默认组为【{{ title || '' }}】，列表中以"-"表示</div>-->
      <div style="margin-top: 10px;color: red;">注:没有配置影院分账组则为默认分账组</div>
      <a-table :columns="columns" :dataSource="list" rowKey="id" :pagination="pagination">
        <template #bodyCell="{ column, record, index }">
          <template v-if="column.key === 'time'">
            <div v-if="record.startTime || record.endTime">
              {{getTime(record.startTime)}} - {{getTime(record.endTime)}}
            </div>
            <div v-if="!record.startTime && !record.endTime">-</div>
          </template>
          <template v-if="column.key === 'createTime'">
            {{ transDateTime(record.createTime) }}
          </template>
          <template v-if="column.key === 'updateTime'">
            {{ transDateTime(record.updateTime) }}
          </template>
          <template v-if="column.key === 'action'">
            <a-button v-permission="['ledger_setting_ticket_group']" type="link" @click="onEdit(record)">修改分账组</a-button>
            <a-button v-permission="['ledger_setting_ticket_group']" type="link" @click="onDelete(record)">删除分账组</a-button>
          </template>
        </template>
      </a-table>

      <!--            <a-modal v-model:visible="showModal" destroyOnClose width="800px" title="选择分账组" @ok="onChangeGroup">
                      <a-spin :spinning="loading">
                          <group v-model:value="modelRef.allocateGroupId"></group>
                      </a-spin>
                  </a-modal>-->
      <groupTime
        v-model:showCinemaModal="showCinemaModal"
        :cinemaAllList="cinemaAllList"
        :groupList="groupList"
        v-model:item="addFormState"
        v-model:isEdit="isEdit"
        :type="formState.type"
        @updateData="getData"
      ></groupTime>
    </a-spin>
  </div>
</template>

<script>
  import {Icon} from '@/components/icon/icon.js';
  import group from '@/views/ledger/goodsSetting/components/group.vue';
  import groupTime from '@/views/ledger/goodsSetting/components/groupTime.vue';
  import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
  import { ledgerGroupList, getAllocateGroupCinemaList, allocateGroupCinema, batchAllocateGroupCinema, delAllocateGroupCinema } from '@/service/modules/ledger.js';
  export default {
    components: { Icon, group, groupTime },
    data() {
      return {
        loading: false,
        title: '',
        organizationList: [],
        cinemaAllList: [],
        groupList: [],
        formState: {
          type: 4,
          //organizationId: 0,
          cinemaId: 0,
          allocateGroupId: 0
        },
        searchData: {},
        selectedRowKeys: [],
        selectedListData: [],
        list: [],
        columns: [{
          title: '所属影院',
          dataIndex: 'cinemaName'
        }, {
          title: '分账组',
          dataIndex: 'allocateGroupName'
        }, {
          title: '分账时间',
          key: 'time'
        }, {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime'
        }, {
          title: '更新时间',
          dataIndex: 'updateTime',
          key: 'updateTime'
        }, {
          title: '操作',
          key: 'action',
          align: 'center',
        }],
        pagination: {
          showSizeChanger: true,
          showQuickJumper: true,
          size: "small",
          showTotal: (total) => {
            return "共 " + total + " 条";
          },
          total: 0,
          current: 1,
          pageSize: 20,
          onChange: (page) => {
            this.pagination.current = page;
            this.getData();
          },
          onShowSizeChange: (page, pageSize) => {
            this.pagination.current = 1;
            this.pagination.pageSize = pageSize;
            this.getData();
          }
        },
        showModal: false,
        showTimeModal: false,
        showCinemaModal: false,
        cinemaModalLoading: false,
        addFormState: {
          cinemaIds: [],
          allocateGroupId: '',
          time: []
        },
        isEdit: 0,
        modelRef: {},
        type: 0
      }
    },
    created() {
      this.getOrganizationList();
      this.getLedgerGroupList();
      //this.onSearch();
    },
    methods: {
      onBack(isRef) {
        this.showModal = false;
        if (isRef) {
          this.$nextTick(() => {
            this.getData();
          })
        }
      },
      onSearch() {
        this.pagination.current = 1;
        // this.pagination.pageSize = 10;
        this.searchData = JSON.parse(JSON.stringify(this.formState));
        this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
        this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
        this.searchData.allocateGroupId = this.searchData.allocateGroupId ? this.searchData.allocateGroupId : undefined;
        this.getData();
      },
      reset() {
        this.$refs.form.resetFields();
        if (this.organizationList.length) {
          this.formState.organizationId = this.organizationList[0].id;
          this.getAllCinemaList(this.formState.organizationId);
        }
        this.onSearch();
      },
      async getData() {
        this.loading = true;
        try {
          let ret = await getAllocateGroupCinemaList({
            page: this.pagination.current,
            pageSize: this.pagination.pageSize,
            type: 4,
            ...this.searchData
          })
          this.loading = false;
          if (ret.code === 200) {
            this.pagination.total = ret.data.totalCount;
            this.list = ret.data.list;
          }
        } catch(e) {
          this.loading = false;
        }
      },
      async getOrganizationList() {
        this.loading = true;
        try {
          let ret = await getOrganizationList({
            page: 1,
            pageSize: 999999
          });
          if (ret.code === 200) {
            if(ret.data.list && ret.data.list.length) {
              this.formState.organizationId = ret.data.list[0].id;
              this.getAllCinemaList(this.formState.organizationId);
            }
            this.organizationList = ret.data.list;
            this.onSearch();
          } else {
            this.loading = false;
          }
        } catch(e) {
          this.loading = false;
        }
      },
      async getAllCinemaList(organizationId) {
        this.cinemaAllList = [];
        this.formState.cinemaId = 0;
        let ret = await getCinemaList({
          page: 1,
          pageSize: 999999,
          organizationId: organizationId ? organizationId : undefined
        })
        if (ret.code === 200) {
          this.cinemaAllList = ret.data.list;
        }
      },
      async getLedgerGroupList() {
        let ret = await ledgerGroupList({
          page: 1,
          pageSize: 999999,
        })
        if (ret.code === 200) {
          let data = ret.data.list;
          for (let i = 0; i < data.length; i++) {
            if (data[i].isDefault) {
              this.title = data[i].title;
              break;
            }
          }
          this.groupList = ret.data.list;
        }
      },
      onSelect(type, item) {
        this.type = type;
        if (this.type) {
          this.modelRef.id = 0;
          this.modelRef.allocateGroupId = [];
        } else {
          this.modelRef.id = item.id;
          this.modelRef.allocateGroupId = [item.allocateGroupId];
        }
        this.showModal = true;
      },
      async onChangeGroup() {
        this.loading = true;
        try {
          let ret;
          if (this.type) {
            ret = await batchAllocateGroupCinema({
              type: 4,
              cinemaIds: this.selectedRowKeys.join(','),
              allocateGroupId: this.modelRef.allocateGroupId[0]
            });
          } else {
            ret = await allocateGroupCinema({
              type: 4,
              cinemaId: this.modelRef.id,
              allocateGroupId: this.modelRef.allocateGroupId[0]
            });
          }
          this.loading = false;
          if (ret.code === 200) {
            this.$message.success('修改成功');
            if (this.type) {
              this.selectedRowKeys = [];
              this.selectedListData = [];
              this.modelRef.allocateGroupId = [];
            }
            this.showModal = false;
            this.getData();
          }
        } catch(e) {
          this.loading = false;
        }
      },
      onSelectChange(record, selected) {
        if (selected) {
          if (this.selectedRowKeys.length < 500) {
            this.selectedListData.push(JSON.parse(JSON.stringify(record)));
            this.selectedRowKeys.push(record.id);
          } else {
            this.$message.warn('最大可选择数据不允许大于500条');
          }
        } else {
          this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
          this.selectedListData = this.selectedListData.filter(item => {
            return item.id !== record.id;
          });
        }
      },
      onSelectAll(selected) {
        if (selected) {
          let showWain = true;
          this.list.forEach(item => {
            if (this.selectedRowKeys.length < 500) {
              if (this.selectedRowKeys.indexOf(item.id) === -1) {
                this.selectedListData.push(JSON.parse(JSON.stringify(item)));
                this.selectedRowKeys.push(item.id);
              }
            } else {
              if (showWain) {
                showWain = false;
                this.$message.warn('选择数据不允许大于500条');
                return;
              }
            }
          })
        } else {
          this.list.forEach(item => {
            if (this.selectedRowKeys.indexOf(item.id) !== -1) {
              this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
              this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
            }
          })
        }
      },
      onAddTap() {
        this.showCinemaModal = true;
        this.addFormState.cinemaIds = [];
        this.addFormState.allocateGroupId = '';
        this.addFormState.time = [];
        this.addFormState.id = 0;
        this.isEdit = 0;
      },
      onEdit(item) {
        this.addFormState.cinemaIds = [item.cinemaId];
        this.addFormState.allocateGroupId = item.allocateGroupId;
        if (item.startTime || item.endTime) {
          let time0 = this.moment.duration(item.startTime, 'seconds')
          let time1 = this.moment.duration(item.endTime, 'seconds')
          this.addFormState.time = [this.moment.utc(time0.asMilliseconds()), this.moment.utc(time1.asMilliseconds())];
        } else {
          this.addFormState.time = [];
        }
        this.addFormState.id = item.id;
        this.isEdit = item.id;
        this.showCinemaModal = true;
      },
      async onDelete(item) {
        this.$confirm({
          title: '提示',
          content: '确定删除该分账配置?',
          onOk: async ()=> {
            this.loading = true;
            try {
              const {code} = await delAllocateGroupCinema({
                id: item.id
              });
              if (code == 200) {
                this.getData();
              }
              this.loading = false;
            } catch (e) {
              this.loading = false;
            }
          }
        })
      },
      getTime(seconds) {
        const duration = this.moment.duration(seconds,'seconds')
        let h,m,s;
        h = Math.floor(duration.asHours()) < 10 ? `0${Math.floor(duration.asHours())}` : Math.floor(duration.asHours());
        m = duration.minutes() < 10 ? `0${duration.minutes()}` : duration.minutes();
        s = duration.seconds() < 10 ? `0${duration.seconds()}` : duration.seconds();
        return `${h}:${m}:${s}`
      },
    }
  }
</script>

<style scoped>
    .ui-form__item {
        margin-right: 30px;
    }
</style>
